<template>
  <div id="Land">
    <div class="container">

      <div class="content link-box">
        <img class="logo" src="@/assets/tycoo.png" />

        <div class="email area w-11/12 md:9/12 mx-auto my-7 flex flex-col text-center justify-center items-center">
          <p class="text-white text-left mb-8">Sign up for my newletter to be the first recieve information and content as the world progresses into a Web3.0 Economy</p>

          <p class="text-white font-bold " v-if="feedback">{{feedback}}</p>

            <div>
              <form action="">
                <div class="flex justify-center items-center">

                  <div class="md:mb-6">
                    <input
                      type="text"
                      class="
                        form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      "
                      v-model="email"
                      id="exampleFormControlInput1"
                      placeholder="@email"/>
                  </div>

                  <div class="md:mr-auto mb-6">

                    <button @click.prevent="subscribeToMailingList()"  v-if="!processing"
                    class="inline-block px-6 py-2 border-2 border-white text-white font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out ml-2"
                    style="background-color: var(--primary);">Subscribe</button>
                    <p v-if="processing">Please Wait</p>
                  </div>

                </div>
              </form>
            </div>

          </div>


        <p class="  text-white text-md">Enter the world of Web3.0</p>
        <a href="/infographics" class="primary-btn2 ">Enter</a>
        <!-- <router-link class="primary-btn2" :to="{name:'Home'}">Infographics</router-link> -->
        <!-- <router-link class="primary-btn2" :to="{name:'Contact'}">Contact</router-link> -->
        <div class="social-list">
          <a class="link-to" target="_blank" :href="link.route" v-for="(link, idx) in links" :key="idx">
            <img :src="link.icon" />
          </a>
        </div>
      </div>


      <div class="media-box">
        <div
        class="img-box img-1"
        :style="{'background-image': 'url(' + require('@/assets/images/info.png') + ')'}"></div>

        <div class="img-box web3-box img-2"
          :style="{'background-image': 'url(' + require('@/assets/images/web3.png') + ')'}">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import NavBar from '@/components/_home/NavBar.vue'
import axios from 'axios'

export default {
  name: 'Land',
  components: {

  },
  data(){
    return{
      email: '',
      feedback:'',
      feedtype:'',
      processing: false,
      links:[
        {
        name:'Twitter',
        icon: require('@/assets/icons/social-media/twitter.svg'),
        route: 'https://twitter.com/tycooperaow'},
        {
        name:'Instagram',
        icon: require('@/assets/icons/social-media/instagram.svg'),
        route: 'https://www.instagram.com/tycooperaow2.0'},
        {
        name:'Medium',
        icon: require('@/assets/icons/social-media/medium.svg'),
        route: 'https://tycooperaow.medium.com'},
        {
        name:'Youtube',
        icon: require('@/assets/icons/social-media/youtube.svg'),
        route: 'https://www.youtube.com/channel/UCLxaPajGVw9K-gH9O69a0nw'},
        {
        name:'TikTok',
        icon: require('@/assets/icons/social-media/tik-tok.svg'),
        route: 'https://www.tiktok.com/@tycooperaow'},
        {
        name:'Telegram/Contact',
        icon: require('@/assets/icons/social-media/telegram.svg'),
        route: 'https://t.me/tycooperaow'}
      ]
    }
  },
  methods:{
    subscribeToMailingList(){
      if(!this.email){
        this.feedback= "You must enter a valid email!"
        this.feedtype= "danger"
      }else{
        if(this.email.includes('@') && this.email.includes('.')){
          axios.post('https://api.skylineexecutive.com/newsletter-crypto', {
            email: this.email.toLowerCase()
          }).then((res)=>{
              this.feedback=res.data.feedback
              this.feedtype=res.data.feedtype
              // alert(res.data.dull_list)
          }).catch((err) => {
            this.error = err
          })
        }else{
          this.feedback = "You must enter a valid email!"
          this.feedtype= "danger"
        }
      }
    },
  },
}
</script>
<style scoped>
#Land{
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.container{
  width: 80%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content:space-evenly;
  align-items:center;
}
.content{
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.media-box{
  width: 50%;
  position: relative;
  display: none ;
}
.logo{
  width: 80%;

  margin: auto;
}
.img-box{
  position:absolute;
  display: none ;
  width: 200px;
  height: 300px;
  border-radius: 5px;
  border: 5px solid #fff;
  overflow:hidden;
  /* Create the parallax scrolling effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
.web3-box{
  width: 325px;
  height: 200px;
}
.img-1{
  top: 0;
  left:0;
  z-index:2;
}
.img-2{
  bottom: 2rem;
  right: 0;
  z-index:3;
}
.social-list{
  display: flex;
  justify-content:space-evenly;
  align-items:center;
}
.link-to img{
  width: 2rem;
}
.primary-btn2{
  min-width: 15rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items:center;
  background-color: var(--primary);
  padding: 1rem 2rem;
  height:100%;
  max-width: 20%;
  margin: 0 auto;
  text-decoration: none;
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #fff;
  border-radius: 10px;
  transition: 1s;
  box-shadow: 0 0 0 0 rgba(#222, .5);
  -webkit-box-shadow: 0 0 0 0 rgba(#222, .5);
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 2s infinite;
}
.primary-btn3{
  background-color: var(--primary);
}
.primary-btn2:hover{
  -webkit-animation: none;
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@media only screen and (min-width: 900px) {
  .container{
  /* flex-direction: column; */
  }
  .primary-btn3{
    min-width: 8rem;
    font-size: 1rem;
  }
  .media-box{
    display: block;
  }
  .img-box{
    display: block ;
  }
  .web3-box{
    display: block ;
  }
  .link-to img{
    width: 3rem;
  }
}
</style>
